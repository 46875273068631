$ergoRed: #8E0038;
$ergoRedDisabled: #D4A2B6;
$red: #BF1528;
$black: #000000;
$black_background: #424242;
$default-gray-background: #F0F0F0;
$IconBackgroundGray: #E3E3E3;
$white: #FFFFFF;
$pink_accent: #D4A2B6;
$red_accent: #6A0029;
$redFunction: #e80c26;
$lightBlack: rgba(0, 0, 0, 0.80);


$green-1: #b5d5c1;
$green-2: #e3f0e6;
$green-3: #8bc9ad;
$green-4: #5fb8a2;
$green-5: #009284;

$purple-1: #E4D6E4;
$purple-2: #efe7ed;
$purple-3: #dbaccc;
$purple-4: #ce5e92;
$purple-5: #b31767;

$blue-1: #CCEBED;
$blue-2: #e2f6fa;
$blue-3: #a9dae0;
$blue-4: #47bcc5;
$blue-5: #0f94a7;

$yellow-1: #F8E89D;
$yellow-2: #fff4b0;
$yellow-3: #f7db6f;
$yellow-4: #fad73b;
$yellow-5: #f6cb00;

$orange-1: #F7DCBB;
$orange-2: #ffeede;
$orange-3: #f3c591;
$orange-4: #efa56d;
$orange-5: #e87a16;

$warm-1: #D9D5C7;
$warm-2: #ebe6d8;
$warm-3: #bbb9ad;
$warm-4: #807e6f;
$warm-5: #545241;

/***** Shadow ******/
$ergoShadow-1: 0px 4px 21px 0px rgba(0, 0, 0, 0.08);
$ergoShadow-2: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
$shadow-3: 0px 4px 8px rgba(0, 0, 0, 0.2);

/***** Border *****/
$border-1: rgba(0, 0, 0, 0.6);
$table-border: rgba(0, 0, 0, 0.1);

  /***** Buttons ******/
$primary_button_green: $green-5;
$primary_button_red: $ergoRed;
$primary_button_orange: $orange-5;
$primary_button_default: $black_background;
$primary_button_text: $white;

$secondary_button_red: $white;
$secondary_button_red_text: $ergoRed;

/***** Icons ******/
$success_green_icon: $green-5;
$error_red_icon:$purple-5;
$warning_orange_icon: $orange-5;


/***** font ******/
$headline_color: $ergoRed;
$marquee_text: $black;
$description_text_gray: #A4A4A4;

/***** other ******/
$warning_background_red: $pink_accent;
$warning_background_orange: $orange-1;
$container-background_gray: #E4E4E4;

/**** State Colors ****/
$open_enum: $black;
$started_enum: $blue-5;
$accepted_enum: $green-5;
$rejected_enum: $ergoRed;
$reopened_enum: $orange-5;

/**** Icon Colors ****/
.red {
  svg path{
    fill: $ergoRed!important;
  }
}
.white {
  svg path{
    fill: $white!important;
  }
}
.black {
  svg path{
    fill: $black!important;
  }
}




















