@import "spacing";
@import "colors";



label p {
  margin-bottom: 0px!important;
}


mat-card {
  box-shadow: none;
  padding: $mat-card-padding-space;
}


app-table-sort {
  display: flex!important;
}

/// prüüüfen


/*******MatCard********/
mat-card {

  &.infoBox {
    background-color: $default-gray-background;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

app-hint mat-card{
  padding: unset!important;
}

app-hint + app-hint > mat-card {
  margin-top: $infoBoxPadding!important;
  margin-bottom: $section-space;
}

// Icon-Text button
.mdc-fab {
  border-radius: $button-border-radius;
  padding: $buttonPadding;
  box-shadow: none;
  height: auto;
}

button.primary {
  p {
    margin: 0;
    color: $white;
  }
  &.mdc-button.mat-mdc-button.mat-unthemed.mat-mdc-button-base {
    border-radius: $button-border-radius;
    height: $textButtonHeight;
    background: $ergoRed;
    color: $white;
    padding: $textButtonPadding!important;
  }
}

// Datepicker
.mat-mdc-button{
  color: $ergoRed;
}

//table
.cdk-header-row{
  background: $default-gray-background!important;
  border-bottom: 2px solid $ergoRed!important;

  th {
    background: $container-background_gray!important;
  }
  th:last-child {
    background: $container-background_gray!important;
    border-radius: 0px $boxBorderRadius 0px 0px;
  }
  th:first-child {
    border-radius: $boxBorderRadius 0px 0px 0px;
  }
}

th, td {
  text-align: left;
}

.mat-table
{
  background-color:transparent!important;
}
table {
  box-shadow: none!important;
  border-collapse: collapse;
  border-radius: 5em;

  .header-icon-row {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
  }
}
table tr:last-child td
{
  border-bottom:0 solid
}


//INPUT
:host ::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  border-bottom: none;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent!important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple{
  border: none;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
  border: none;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border: none;
}

.mdc-line-ripple::after{
  border: none!important;
}
.mdc-line-ripple::before {
  border: none!important;
}
.mdc-text-field--filled {
  background-color: $default-gray-background!important;
}

.mat-mdc-form-field-infix {
  width: auto;
}

.mat-mdc-input-element {
  border: none;
  background-color: white!important;
  padding: $inputPadding!important;
  border-radius: $inputRadius $inputRadius 0px 0px!important;
  border-bottom: 1px solid $border-1!important;
}

.consent {
  label.mdc-label{
    padding-top: 8px!important;
  }
}

.two-element-row {
  //input feld abstand zwischen zwei elementen

  mat-form-field {
    padding-left: 10px;
  }
  mat-form-field:first-child{
    padding-right: 10px;
    padding-left: unset;
  }
  }


.mat-mdc-form-field{
  background: $default-gray-background;
}


// textArea
textarea {
  border: none;
  background-color: white!important;
  border-radius: $inputRadius $inputRadius 0px 0px!important;
  border-bottom: 1px solid $border-1!important;
  height: $inputFieldsHeight;
}

mat-label{
  color: $black;
}

/* Border */
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: white;
}
/* Font color */
input.mat-input-element {
  color: white;
}

/* Checkbox */
 mat-checkbox .mdc-form-field.mat-internal-form-field {
   display: flex;
   align-items: flex-start;
 }

.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
   background-color: $white!important;
 }

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: $ergoRed!important;
  background-color: $ergoRed!important;
}

.checkbox-padding {
  padding-top: $checkbox-padding;
}

/*radio button*/
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: $ergoRed!important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: $ergoRed!important;
}


/*mat-calender*/
mat-calendar {
  span.mdc-button__label {
    color: $ergoRed;

    span {
      font-size: 20px!important;
      font-style: normal;
      font-weight: 700!important;
      line-height: 28px!important;
    }

  }
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
  }

  span.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-today {
    border-color: $ergoRed;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: $ergoRed;
  }

  .mat-calendar-body-cell-content:hover{
    color: white!important;
  }
  .mat-calendar-body-selected {
    background-color: $ergoRed;
  }

  .mat-calendar-body-cell-content {
    width: 55% !important;
    height: 55% !important;
  }

  .mat-calendar-table-header-divider::after {
    background: $ergoRed;
    left: 17px;
    right: 17px;
  }
}

/* scrollbalken */
.mat-dialog-content,
.mat-drawer-content,
.mat-sidenav-content,
.mat-select-panel,
.mat-menu-panel,
.mat-autocomplete-panel {
  overflow-y: hidden !important;
}

.full-with {
  width: 100%;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset!important;
}
.mdc-text-field--filled{
  padding: unset!important;
}

app-edit-form-dialog .mat-mdc-form-field-infix {
  padding-right: 10px!important;
  padding-left: 10px!important;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow-y: hidden;
}

/**errror**/
mat-error {
  margin-left: -10px;
  line-height: 1.5;
}

.mat-mdc-form-field-error {
  display: block;
  color: $redFunction!important;

}

.mat-form-field-invalid {
  border-left: 4px solid $redFunction;
  margin-top: 20px;
  margin-bottom: 20px;

  .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding:0px!important;
    margin-bottom: 16p;
  }

  .mat-mdc-form-field-subscript-wrapper{
    color:orange;
    display: block;
  }

  label{
    color: $redFunction;
  }

  .mat-mdc-input-element {
    border-bottom: 1px solid $redFunction!important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    line-height: 0;
  }

  .mat-mdc-form-field-infix{
    margin-left: 6px;
  }
}

.mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 0px 0px 20px 0px!important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mat-mdc-form-field-error-wrapper {
  display: block;
}

mat-select{
  height: 45px;
  vertical-align: center;
  align-items: center;
  display: flex;

  border: none;
  background-color: white!important;
  padding: $inputPadding!important;
  border-radius: $inputRadius $inputRadius 0px 0px!important;
  border-bottom: 1px solid $border-1!important;
}

mat-card {
  border-radius: 8px!important;
}

.mat-mdc-card-content:last-child{
  padding: unset;
}

.error .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: $redFunction!important;
  background-color: transparent;
}

.error p{
  color: $redFunction;
}

/****specialstyle**/

app-financial-assistance-key-value-component {
  mat-card {
    padding: 0px!important;
  }
  h5 {
    padding-left: 16px!important;
    padding-top: 16px!important;
  }
}

app-wizard-sponsoring-confirmation app-wizard-footer .save-button {
  margin-top: 0px!important;
}

app-wizard-sponsoring-conclusion app-text-button {
  display: flex;
  justify-content: center;
}

app-applicant-detailoverview app-wizard-sponsoring-consent .container-wizard {
  padding-bottom: 0px!important;
}

.mdc-linear-progress__bar {
  height: 8px!important;
}
.mdc-linear-progress__buffer {
  height: 8px!important;
}
.mdc-linear-progress__bar-inner {
  border-top-width: 8px!important;
  height: 8px!important;
}

ul {
  margin-bottom: 0px!important;
}
.mat-mdc-dialog-surface.mdc-dialog__surface {
  height: auto!important;
}

/** paginator **/
.mat-mdc-paginator-container {
  background-color: $default-gray-background!important;
}
.mat-mdc-paginator-page-size {
  display: none!important;
}
.mat-mdc-paginator-range-label {
  margin: 0 6px 0 24px!important;
  font-family: 'FSMe', sans-serif!important;;
  font-weight: 400!important;
  font-size: 1rem!important;
  line-height: 22.4px!important;
}

mat-tooltip-component {
  display: none !important;
}

// material icon color change
.icon-arrow svg path {
  fill:$ergoRed!important;
}

/**spinner **/
.spinner-container {
  height: 472px;
  display: flex;
  justify-content: center;
  align-items: center;
}

mat-icon.whiteIcon svg path {
  fill: $white!important;
}

app-media-box app-icon-text-button .secondaryIcon {
  text-align: left;
  p {
    margin-left: 0px!important;
    width: 190px!important;
    letter-spacing: normal;
  }
  align-items: end!important;
}

app-media-box app-icon-text-button button {
  padding: 0px!important;
  margin-top: 20px;
  height: 100% !important;
}

app-media-box app-icon-text-button .button-font {
  max-width: 232px;
}

th.middle{
  width: 475px!important;
}

th.large {
  width: 50%!important;
}

th.small{
  width: 200px!important;
}

tr {
  cursor: pointer!important;
}

td p {
  margin-bottom: 8px!important;
  margin-top: 8px!important;
}


.commentFieldStyle {
  padding: 10px;
  min-height: 75px;
}
