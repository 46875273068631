@charset "UTF-8";
@import "colors";


@font-face {
  font-family: "Fedra Serif A Book";
  src: url("../fonts/WF-038022-012105-000003.woff2") format("woff2"), url("../fonts/WF-038022-012105-000003.woff") format("woff");
}

@font-face {
  font-family: "Fedra Serif A Medium";
  src: url("../fonts/WF-038022-012105-000050.woff2") format("woff2"), url("../fonts/WF-038022-012105-000050.woff") format("woff");
}

@font-face {
  font-family: "Fedra Serif A Bold";
  src: url("../fonts/WF-038022-012105-000054.woff2") format("woff2"), url("../fonts/WF-038022-012105-000054.woff") format("woff");
}

@font-face {
  font-family: "FSMeBold";
  src: url("../fonts/FSMeBold/FSMeBold.woff2") format("woff2"), url("../fonts/FSMeBold/FSMeBold.woff") format("woff");
}
@font-face {
  font-family: "FSMe";
  src: url("../fonts/FSMe/FSMe.woff2") format("woff2"), url("../fonts/FSMe/FSMe.woff") format("woff");
}
@font-face {
  font-family: "FSMeLight";
  src: url("../fonts/FSMeLight/FSMeLight.woff2") format("woff2"), url("../fonts/FSMeLight/FSMeLight.woff") format("woff");
}

body {
  color: $marquee_text;
}

.text-center {
  text-align: center;
}

button {
  font-family: 'FSMe', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

h1 {
  font-family: 'Fedra Serif A Medium', serif!important;
  color: $headline_color;
  font-weight: 500!important;
  margin-bottom: 12px;
}
h2 {
  font-family: 'Fedra Serif A Medium', serif!important;
  color: $headline_color;
  font-weight: 500!important;
  margin-bottom: 12px;
}
h3 {
  font-family: 'FSMeBold', sans-serif!important;;
  font-weight: 700!important;
  margin-bottom: 12px;
}
h4 {
  font-family: 'FSMeBold', sans-serif!important;;
  font-weight: 700!important;
}
h5 {
  font-family: 'FSMeBold', sans-serif!important;;
  font-weight: 700!important;
}
.lead{
  font-family: 'FSMe', sans-serif!important;;
  font-weight: 400!important;
  margin-bottom: 0px;
}
p {
  font-family: 'FSMe', sans-serif!important;;
  font-weight: 400!important;
}

.font-c1 {
  font-family: 'FSMe', sans-serif!important;;
  font-weight: 400!important;
}
.font-c2 {
  font-family: 'FSMe', sans-serif!important;;
  font-weight: 400!important;
}
.font-c3 {
  font-family: 'FSMe', sans-serif!important;;
  font-weight: 400!important;
}
.font-navigation {
  font-family: 'FSMe', sans-serif!important;;
  font-weight: 300!important;
  color: rgba(0, 0, 0, 0.80);
}

/* Media Query für kleine Bildschirme (z.B. Smartphones) */
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 1.5rem;
    line-height: 33.6px;
  }
  h2 {
    font-size: 1.375rem;
    line-height: 30.8px;
  }
  .lead {
    font-size: 1.125rem;
    line-height: 25.2px;
    font-weight: 400;
  }
  h3 {
    font-size: 1.125rem;
    line-height: 25.2px;
    font-weight: 700;
  }
  h4 {
    font-size: 1rem;
    line-height: 22.4px;
  }
  h5 {
    font-size: 0.875rem;
    line-height: 19.6px;
  }
  p {
    font-size: 0.875rem;
    line-height: 19.6px;
  }
  .font-c1 {
    font-size: 1rem;
    line-height: 22.4px;
  }
  .font-c1-link {
    font-size: 1rem;
    line-height: 22.4px;
    font-weight: 700!important;
  }
  .font-c2 {
    font-size: 0.875rem;
    line-height: 19.6px;
  }
  .font-c2-link {
    font-size: 0.875rem;
    line-height: 19.6px;
    font-weight: 700!important;
  }
  .font-c3 {
    font-size: 0.75rem;
    line-height: 19.2px;
  }
  .font-navigation {
    font-size: 0.875rem;
    font-style: normal;
    line-height: normal;
  }
}

/* Media Query für mittlere Bildschirme (z.B. Tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 1.625rem;
    line-height: 36.4px;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 33.6px;
  }
  h3{
    font-size: 1.25rem;
    line-height: 28px;
    font-weight: 700;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 25.2px;
  }
  h5 {
    font-size: 1rem;
    line-height: 22.4px;
  }
  .lead {
    font-size: 1.25rem;
    line-height: 28px;
  }
  p {
    font-size: 1rem;
    line-height: 22.4px;
  }
  .font-c1 {
    font-size: 1.125rem;
    line-height: 25.2px;
  }
  .font-c1-link {
    font-size: 1.125rem;
    line-height: 25.2px;
    font-weight: 700!important;
  }
  .font-c2 {
    font-size: 1rem;
    line-height: 22.4px;
  }
  .font-c2-link {
    font-size: 1rem;
    line-height: 22.4px;
    font-weight: 700!important;
  }
  .font-c3 {
    font-size: 0.75rem;
    line-height: 19.2px;
  }
  .font-navigation {
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
  }


}

/* Media Query für große Bildschirme (z.B. Desktop) */
@media only screen and (min-width: 992px)    {
  h1 {
    font-size: 2rem;
    line-height: 44.8px;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 39.2px;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 33.6px;
  }
  h4 {
    font-size: 1.25rem;
    line-height: 28px;
  }
  h5 {
    font-size: 1rem;
    line-height: 22.4px;
  }

  .lead{
    font-size: 1.5rem;
    line-height: 33.6px;
  }
  p {
    font-size: 1rem;
    line-height: 22.4px;
  }
  .font-c1 {
    font-size: 1.25rem;
    line-height: 28px;
  }
  .font-c1-link {
    font-size: 1.25rem;
    line-height: 28px;
    font-weight: 700!important;
  }
  .font-c2 {
    font-size: 1rem;
    line-height: 22.4px;
  }
  .font-c2-link {
    font-size: 1rem;
    line-height: 22.4px;
    font-weight: 700!important;
  }
  .font-c3 {
    font-size: 0.75rem;
    line-height: 19.2px;
  }
  .font-navigation {
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
  }
}


.subtitle {
  border-bottom: 1px solid $ergoRed;
}

.readonlyText {
  color: $description_text_gray!important;
}

strong{
  font-family: 'FSMeBold', sans-serif!important;;
  font-weight: 700!important;
}
