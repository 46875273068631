/** Padding **/

$headerBarPadding: 13px 0px;

$secondaryButtonPadding: 10px 20px;
$buttonPadding: 10px 40px;
$containerPadding: 100px 0px 158px 0px;


$footerHeight: 58px;
$headerHeight: 137px;
$bodyHeight: calc(100% - $headerHeight - $footerHeight - 200px);
$footerPadding: 16px;

.container-wizard {
  padding: $containerPadding;
}


  /* spacing layout */
  $special-margin-space: 12px;
  $section-space: 50px;
  $element-space: 30px;
  $regular-element-space:24px;
  $special-padding-space: 45px;
  $mat-card-padding-space: 50px 30px;

  /***** IconSizes ******/
  $borderRadiusIconRound: 50%;
  $paddingIconRound: 12px;

  $icon-xsmall: 20px;
  $icon-small: 24px;
  $icon-medium: 32px;
  $icon-large: 40px;
  $icon-xlarge: 70px;


  /***** CHIP ***/
  $chipPadding: 4px 16px;
  /***** Buttons ******/

  /**iconTextButton**/

  $icon_text_padding: 10px;
  $button-border-radius: 150px;

  /**TextButton**/
  $textButtonPadding: 10px 40px 10px 40px;
  $textButtonHeight: 48px;
  $textButtonMargin: 20px;

  /*ButtonIconSizes**/
  $button-icon-small: 12px;
  $button-icon-medium: 24px;
  $button-icon-large: 32px;

  /***** BackgroundBox ******/
  $boxBorderRadius: 8px;
  $boxBorderRadiusSmall: 4px;
  $boxPadding: 50px 30px;
  $boxPaddingSmall:16px;

  /***** Infobox ******/
  $infoBoxRadius: 4px;
  $infoBoxPadding:16px;
  $infoBoxSectionPadding:30px;


  /****** BoxBackground *****/
  $boxBodyPadding: 30px;
  $boxBodyRadius: 8px;

  /****** MediaBox ********/
  $pdfMediaBoxBottom: 32x;

  /***** Datepicker ******/
  $datepickerWidth: 340px;


  /**** InputField *****/
  $inputPadding: 10px;
  $inputRadius: 4px;
  $inputFieldsHeight: 45px;

  $labelFieldSpace: 10px;
  $textMarginDefault: 16px;

  /**** Checkbox ******/
  $checkbox-padding: 8px;

  /***dataUpload**/
  $fileSpace: 20px;
  $viewerIconWidth: 54px;
  $viewerIconHeight: 54px;
  $viewerIconRadius: 8px;
  $viewerIconPadding:7px;


  .hintSpaceIcon {
    margin-left: 10px;
  }
  .hintSpaceText {
    margin-left: 42px;
  }

  /**** Dialog *****/
  $padding-dialog-container: 24px;



  /****** font ********/
  .wizard-container {
    height: $bodyHeight;
    padding-top: 40px;
    padding-bottom: 100px;
  }

  // zu sortieren und neu benennen
  .element-space {
    margin-bottom: $element-space;
  }
  .element-space-top {
    margin-top: $element-space;
  }
  .section-space-default {
    margin-bottom: $section-space!important;
  }

.sectionSpaceDefaultTop {
  margin-top: $section-space!important;
}
  .sectionSpace {
    margin-bottom: calc($section-space - $textMarginDefault);
  }

  .section-space-large {
    margin-bottom: 45px;
  }


