@import 'bootstrap-grid-only/bootstrap.css';
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "./spacing";
@import "./colors";
@import "./table-style";
@import "./material-override";
@import "./fonts";

html, body { height: 100%; box-sizing: border-box; overflow: scroll; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: $default-gray-background}

/***** Box ******/
$boxBackground: $white;
$boxRadius: 8px;

app-dashboard {
  white-space: pre-line!important;
}

app-icons {
  line-height: 1;
}

.nextButton {
  display: flex;
  justify-content: center;
}


.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #8E0038!important;
  --mdc-linear-progress-track-color: rgba(0, 0, 0, 0.3)!important;
}

.spinner {
  display: flex!important;
  justify-content: center!important;
  height: 100vh!important;
  align-items: center!important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle, .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: $ergoRed!important;
}

app-edit-form-dialog app-wizard-sponsoring-financial-assistance {
  .container-wizard {
    padding: 0px!important;
  }
}

.viewerForContractDocuments .col-lg-12 {
  border: none !important;
  border-left: 2px solid $ergoRed!important;
  padding-left: 20px!important;
  padding-bottom: 20px!important;
  margin-bottom: 0px!important;
}

.viewerForContractDocuments .col-lg-12.section-space-default {
  margin-bottom: 15px!important;
}

app-zuschuss-documents-component app-file-uploader .col-lg-12 {
  border: none !important;
}

#data-upload.container {
  margin-bottom: 40px!important;
}

.h3{
  font-size: 1.5rem!important;
}

@media (max-width: 1200px) {
  button {
    height: auto;
    margin-bottom: 10px;
  }
}


html, body {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

