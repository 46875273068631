@import "spacing";
@import "colors";
mat-card {
  margin-bottom: $section-space;
}

mat-card:has(+ .comment) {
  margin-bottom: $element-space;
}

.comment:has(+ .history) {
  margin-bottom: $element-space;
}

.title {
  padding: 16px;
  background: $container-background_gray;
  border-bottom: 2px solid $ergoRed;
  border-radius: $infoBoxRadius $infoBoxRadius 0px 0px;
}

.key-value-pair {
  padding: 16px;
}

mat-card.mat-mdc-card.mdc-card{
  // padding: 0px!important;
}

mat-card-content.mat-mdc-card-content {
  // padding: 0px!important;
}
.item {
  border-bottom: 1px solid $table-border;
}

.item:not(:has(.row)) {
  border: none;
}

.item:last-child {
  border-bottom: none;
}
